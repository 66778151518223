/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkout-delivery-option-check-mark-color: var(--primary-base-color);
    --checkout-delivery-option-check-mark-hover-color: var(
        --secondary-dark-color
    );
    --checkout-delivery-option-border-color: var(--primary-divider-color);
}

.CheckoutDeliveryOption {
    padding-block: 10px;
    margin-block-end: 0;
    display: flex;
    align-items: flex-start;

    &_isDisabled {
        cursor: not-allowed;
    }

    &:first-child {
        border-block-start: 0;
    }

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0;
    }

    &-Button {
        --check-mark-opacity: 0;
        font-size: 14px;
        width: 100%;
        text-align: start;
        // display: flex;
        cursor: pointer;
        // align-items: center;
        // @include desktop {
        //     align-items: center;
        // }

        &_isDisabled {
            pointer-events: none;
        }

        @include mobile {
            box-shadow: 0px 0px 10px rgb(226, 226, 226);
            border-radius: 15px;
            padding: 20px 10px;
            margin-inline: 5px;
            text-align: center;
        }
    }

    &-FieldTextContainer {
        display: flex;
        align-items: center;
    }

    &-Row {
        @include mobile {
            margin-left: 10px;
        }
        @include desktop {
            margin-left: 10px;
        }
        strong {
            display: inline-block;
            white-space: pre;
            letter-spacing: 1px;
            line-height: 20px;
        }
    }

    &-DeliveryOptionMessage {
        font-size: 13px;
        margin-top: 5px;
        letter-spacing: 1px;
        font-weight: 600;
        color: var(--primary-alt-dark-color);
        margin-bottom: 10px;
        padding-bottom: 5px;
        font-style: italic;
        border-bottom: 1px dashed var(--primary-alt-dark-color);
    }

    &-SubPrice {
        font-size: 12px;
        font-weight: 400;
        text-align: end;
        position: absolute;
        inset-inline-start: 0;
        inset-block-start: 1.5em;
        white-space: nowrap;
    }

    &-Message {
        color: var(--primary-error-color);
    }

    .Field_type_checkbox {
        margin-block-start: 0;
    }
}
