/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CheckoutSuccess {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @include desktop {
        margin-block: 2em;
    }

    &-OrderSuccessDetail {
        box-shadow: 0px 0px 50px rgb(172, 172, 172);
        padding: 20px;
        border-radius: 10px;
        background: #ffffff;
        @include mobile {
            min-width: 200px;
        }
        @include desktop {
            width: 50%;
            min-width: 400px;
        }
    }
    &-SuccessImage {
        text-align: center;

        @include desktop {
            margin-block: 2em;
            width: 100%;
            height: 100%;
            min-width: 200px;
            min-height: 200px;
            .successImg {
                width: 50%;
            }
        }
        @include mobile {
            margin-block: 1em;
            width: 100%;
            height: 100%;
            min-width: 150px;
            min-height: 150px;
            .successImg {
                width: 50%;
            }
        }
    }
    &-SuccessHeading {
        h2 {
            text-align: center;

            color: var(--primary-light-color);
            @include desktop {
                letter-spacing: 4px;
                margin-top: 0;
            }
            @include mobile {
                letter-spacing: 3px;
            }
        }
    }
    h3 {
        color: var(--primary-alt-light-color);
        text-align: center;
        @include desktop {
            letter-spacing: 2px;
        }
        @include mobile {
            letter-spacing: 0.5px;
        }
    }
    p {
        text-align: center;
        line-height: 25px;
        font-size: 16px;
        color: grey;
        @include desktop {
            letter-spacing: 1px;
        }
        @include mobile {
            letter-spacing: 0.5px;
        }
    }

    &-ContinueButton {
        @include mobile {
            width: 100%;
        }

        @include desktop {
            margin-block-start: 24px;
        }
    }

    &-ButtonWrapper {
        text-align: center;
    }

    .Button {
        background-color: rgb(209, 214, 194);
        border: 1px solid rgb(209, 214, 194);
        color: var(--primary-light-color);
        border-radius: 5px;
        &:hover {
            color: rgb(126, 138, 92) !important;
            border: 1px solid rgb(209, 214, 194) !important;
            border-radius: 5px !important;
            background-color: transparent !important;
        }
        @include desktop {
            letter-spacing: 2px;
        }
        @include mobile {
            width: 100%;
            letter-spacing: 2px;
            text-align: center;
        }
    }

    .CheckoutRegistrationLink {
        margin-block-start: 20px;
    }
}
