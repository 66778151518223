/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Checkout {
    .CheckoutShipping {
        &-Button {
            @include desktop {
                width: 100%;
            }
            @include mobile {
                width: 100%;
            }
        }
    }

    &-TotalValue {
        text-align: end;
    }

    &-SubPrice {
        white-space: nowrap;
        display: block;
        font-weight: 400;
        font-size: 12px;
    }
}

.TitleWrapper {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.ViewDetails {
    letter-spacing: 1px !important;
    text-transform: uppercase;
    margin-left: 5px;
    font-size: 11px;
    border-bottom: 1px dashed var(--primary-alt-base-color);
    color: var(--primary-alt-base-color);

    &:hover,
    &:active,
    &:focus {
        color: var(--primary-alt-base-color) !important;
    }
}

.CheckoutShipping {
    &-ShippingActionButtonStickyBar {
        @include mobile {
            border-block-start: 1px solid var(--primary-divider-color);
            position: fixed;
            inset-inline-start: 0;
            width: 100%;
            z-index: 100;
            bottom: 0px;
            background-color: white;
        }
        // @include desktop {
        //     display: flex;
        //     justify-content: center;
        // }

        .Button {
            letter-spacing: 2px;
            &:hover {
                background-color: var(--primary-light-color) !important;
            }
            @include mobile {
                height: 55px;
                width: 100%;
            }
            @include desktop {
                width: 100%;
                max-width: 450px;
                border-radius: 5px;
                &:hover {
                    border-radius: 5px;
                }
            }
        }
    }
}
