/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkout-address-table-border: transparent;
    --checkout-address-table-hover-border: var(--secondary-base-color);
    --checkout-address-table-check-mark-hover-color: var(
        --secondary-dark-color
    );
    --checkout-address-selected-color: var(--primary-base-color);
    --checkout-address-table-check-mark-selected-color: var(
        --secondary-base-color
    );
}

.CheckoutAddressTable {
    width: 100%;
    transition: all 0.3s linear;
    @include mobile {
        margin-bottom: 10px;
    }
    &-Button {
        --check-mark-opacity: 0;
        height: 100%;
        border-radius: 2px;
        transition-property: border-color, background, border-color;
        transition-duration: 150ms;
        transition-timing-function: ease-in;
        text-align: start;
        border-radius: 10px;
        width: 100%;
        @include mobile {
            border: 2px solid var(--checkout-address-table-border);
        }
        .KeyValueTable-Heading {
            .RadioButtonIcon {
                float: inline-end;
                display: inline-block;
                inset-block-start: 3px;
            }
        }

        @include mobile {
            // margin-block: 5px 0;
            // margin-inline: 0 14px;
        }

        @include desktop {
            margin-block-end: 5px;
        }

        &:not(&_isSelected):hover {
            @include mobile {
                // --check-mark-opacity: 1;
                // --checkout-address-table-border: var(
                //     --checkout-address-table-hover-border
                // );
                // --check-mark-color: var(
                //     --checkout-address-table-check-mark-hover-color
                // );
                border: 2px solid var(--checkout-address-table-border);
            }
            @include desktop {
                border: none;
            }
        }

        &_isSelected {
            background-color: rgb(233, 244, 252);
            &:before {
                --rightcheck-color: #fff;
                content: "";
                position: absolute;
                pointer-events: none;
                background: var(--rightcheck-color);
                width: 0.24rem;
                height: 0.24rem;
                box-shadow: 2px 0 0 var(--rightcheck-color),
                    4px 0 0 var(--rightcheck-color),
                    4px -2px 0 var(--rightcheck-color),
                    4px -4px 0 var(--rightcheck-color),
                    4px -6px 0 var(--rightcheck-color),
                    4px -8px 0 var(--rightcheck-color),
                    4px -10px 0 var(--rightcheck-color);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                z-index: 20;
                @include desktop {
                    right: 9px;
                    top: 4px;
                }
                @include mobile {
                    right: 12px;
                    top: 7px;
                }
            }
            &:after {
                content: "";
                position: absolute;
                pointer-events: none;
                background: var(--primary-success-color);
                width: 25px;
                height: 25px;
                z-index: 10;
                @include desktop {
                    right: -7px;
                    top: -7px;
                    border-radius: 50%;
                }
                @include mobile {
                    right: -2px;
                    top: -2px;
                    border-top-right-radius: 10px;
                    border-bottom-left-radius: 30px;
                }
            }
            @include mobile {
                // --check-mark-opacity: 1;
                // --checkout-address-table-border: var(
                //     --checkout-address-selected-color
                // );
                // --check-mark-color: var(
                //     --checkout-address-table-check-mark-selected-color
                // );
                border: 2px solid transparent !important;
            }
            @include desktop {
                box-shadow: 0px 0px 10px rgb(193, 193, 193);
                border: none;
            }
        }
    }

    &-SelectedLabel {
        color: var(--checkout-address-selected-color);
        text-transform: uppercase;

        &::before {
            content: " - ";
            color: $black;
        }
    }

    .KeyValueTable {
        margin: 0;
    }

    td,
    th {
        width: auto;
    }
    .StreetAddress {
        word-break: break-all;
    }
    .CityAddress {
        word-break: break-all;
    }
    dl {
        @include mobile {
            // margin-block-start: 14px;
            // margin-block-end: 28px;
        }
        dd {
            word-wrap: break-word;
            color: var(--primary-base-color);
            letter-spacing: 1px;
            @include desktop {
                line-height: 25px;
                font-size: 13px;
            }
            @include mobile {
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
}
