/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CheckoutAddressBook {
    width: 100%;
    @include mobile {
        margin: 15px 0;
    }
    @include desktop {
        margin: 15px 0;
        background-color: white;
        border-radius: 5px;
        padding: 20px;
    }

    .ExpandableContent-Button {
        @include mobile {
            background-color: antiquewhite;
            padding: 14px !important;
            border-radius: 10px;
        }
    }

    &-Wrapper {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            column-gap: 10px;
            row-gap: 20px;
        }
        @include mobile {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            column-gap: 10px;
            row-gap: 20px;
        }
        @media screen and (max-width: 330px) {
            display: inline;
        }
    }

    &-CustomAddressWrapper {
        @include desktop {
            margin: 14px;
            display: flex;
            justify-content: space-between;
        }
        @include mobile {
            margin: 10px 7px;
        }
    }
    &-BackButton {
        letter-spacing: 2px;
        border-radius: 5px;
        cursor: pointer;
        padding: 10px 20px;
        border: 1px solid var(--primary-alt-base-color);
        color: var(--primary-alt-base-color);
        font-size: 15px;
        font-weight: 600;
        text-transform: capitalize;
        width: 100%;
        max-width: fit-content;
        &:hover {
            font-size: 15px;
            color: var(--primary-base-color);
            border: 1px solid var(--primary-base-color);
            border-radius: 5px;
        }
        @include mobile {
            letter-spacing: 1px;
            width: 100%;
        }
    }

    &-Button {
        letter-spacing: 2px;
        border-radius: 5px;
        cursor: pointer;
        padding: 10px 20px;
        border: 1px solid var(--primary-alt-base-color);
        color: var(--primary-alt-base-color);
        font-weight: 600;
        text-transform: capitalize;
        width: 100%;
        max-width: fit-content;
        @include desktop {
            font-size: 15px;
        }
        @include mobile {
            letter-spacing: 1px;
            width: 100%;
            font-size: 13px;
        }
        &:hover {
            @include desktop {
                font-size: 15px;
            }
            @include mobile {
                font-size: 13px;
            }
            color: var(--primary-base-color);
            border: 1px solid var(--primary-base-color);
        }

        &_isCustomAddressExpanded {
            display: none;
        }
    }

    &-Heading {
        font-weight: 600;
    }

    .FieldForm-Fields {
        @include wide-desktop {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 32px;

            > *:last-child:nth-child(odd) {
                grid-column: span 2;
            }
        }
    }
    .ExpandableContent {
        @include mobile {
            border-top: none;
        }
    }
    .horizontalLine {
        border-top: 1px solid var(--primary-base-color);
        margin-bottom: 2em;
    }
    .CheckoutShippingHeading {
        margin: 0;
    }
}
