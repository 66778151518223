/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --footer-totals-height: 0;
    --total-summery-font-size-desktop: 15px;
    --total-summery-font-size-mobile: 13px;
    --total-summery-font-color: rgb(109, 109, 109);
    --total-summary-cart-item-count-desktop-bg-color: var(
        --primary-light-color
    );
}
.OrderSummary {
    margin-top: 15px;
}
.CheckoutOrderSummary {
    $divider-border: 1px solid var(--input-border-color);
    @include desktop {
        background-color: white;
        border: 1px solid var(--secondary-base-color);
        border-radius: 5px;
        padding-block-start: 24px;
        padding-block-end: 0;
        padding-inline: 10px;
    }
    @include mobile {
        margin-bottom: 30px;
    }

    .ExpandableContent-Button {
        @include mobile {
            background-color: antiquewhite;
            padding: 14px !important;
            border-radius: 10px;
        }
    }
    
    &-Button {
        border-radius: 5px;
        @include desktop {
            width: 100%;
            max-width: 410px;
            letter-spacing: 1.5px;
        }
        &:hover {
            border-radius: 5px !important;
        }

        @include mobile {
            width: 100%;
        }
    }

    &-Header {
        @include mobile {
            display: none;
        }

        h2 {
            margin-block: 0;
            @include desktop {
                margin-bottom: 10px;
                font-size: 20px;
                font-weight: 100;
                letter-spacing: 1px;
            }
        }
    }

    &-ItemsInCart {
        text-transform: none;
        inset-block-start: 2px;
        font-weight: 400;
        letter-spacing: 1px;
        text-align: center;
        font-weight: bolder;

        @include mobile {
            font-size: 14px;
            margin-block: 20px 16px;
            color: var(--primary-light-color);
            border: 1px solid var(--primary-light-color);
            padding: 10px 0;
            border-radius: 30px;
        }
        @include desktop {
            font-size: 16px;
            color: white;
            background-color: var(
                --total-summary-cart-item-count-desktop-bg-color
            );
            display: inline-block;
            margin-inline: auto;
            position: absolute;
            right: 0;
            top: 20px;
            line-height: 30px;
            padding-inline: 5px;
            z-index: 1;
            &:before {
                content: "";
                position: absolute;
                display: block;
                border: 15px solid
                    var(--total-summary-cart-item-count-desktop-bg-color);
                z-index: 1;
                bottom: 0px;
                left: -20px;
                border-right-width: 1.5em;
                border-left-color: transparent;
                z-index: -1;
            }
        }
    }

    &-SummaryItem {
        justify-content: space-between;
        display: flex;
        padding: 12px 0;
        margin-block-end: 0;
        padding-inline-start: 0;
        @include mobile {
            // padding: 14px 0;
        }
        p {
            letter-spacing: 1px;
            color: var(--total-summery-font-color);
            @include mobile {
                font-size: var(--total-summery-font-size-mobile);
            }
            @include desktop {
                font-size: var(--total-summery-font-size-desktop);
            }
        }

        strong {
            letter-spacing: 1px;
            color: var(--total-summery-font-color);
            @include mobile {
                font-size: var(--total-summery-font-size-mobile);
            }
            @include desktop {
                font-size: var(--total-summery-font-size-desktop);
            }
        }

        &:last-child {
            margin-block-end: 0;
        }

        &::before {
            content: none;
        }

        &_withAppendedContent {
            flex-wrap: wrap;
        }

        &_isTotal {
            padding-inline-start: 0;

            @include desktop {
                font-size: 16px;
                border-block-start: 1px solid var(--primary-divider-color);
            }
            @include mobile {
                padding-inline: 10px !important;
            }
        }
    }

    &-OrderItems {
        @include mobile {
            // background-color: var(--secondary-base-color);
            margin-block: 10px;
        }

        h3 {
            text-align: start;
        }
    }

    &-CartItemList {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &-CartItemDescription {
        margin-block-start: 5px;

        p {
            font-size: 13px;
            line-height: 1.5;

            @include mobile {
                font-size: 15px;
            }
        }
    }

    &-CartItem {
        display: grid;
        grid-template-columns: 96px auto auto;
        grid-column-gap: 6px;
        margin: 0 0 6px;
        padding: 0;

        @include mobile {
            grid-template-columns: 112px auto auto;
            grid-column-gap: 7px;
            margin: 0 0 7px;
        }

        &::before {
            content: none;
        }

        > * {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &-Thumbnail {
        width: 96px;
        height: 96px;

        @include mobile {
            width: 112px;
            height: 112px;
        }
    }

    &-Title {
        p {
            margin: 0;
            padding: 0;
            text-align: start;
        }
    }

    &-Details {
        justify-self: end;
    }

    &-ExpandableContent {
        border-block-start: none;

        &Button {
            padding-inline: 0;
        }
    }

    // &-ExpandableContentHeading {
    //     font-size: 18px;
    // }

    .ExpandableContent-ToggleButton {
        height: 20px;
        width: 20px;
        inset-inline-start: 3px;
    }

    &-Coupon {
        @include mobile {
            padding: 10px 14px;
            border-block-end: $divider-border;
            margin: 0 -14px;
            width: auto;
        }
    }

    &-CmsBlock {
        padding: 10px 0;
        border-block-end: $divider-border;
    }

    .ProductPrice {
        margin-block-start: 0;
        font-weight: bold;
        line-height: 1;
        font-size: 18px;

        &-SubPrice {
            display: block;
            font-size: 12px;
        }
    }

    .CartItem-Wrapper {
        // padding: 12px 0;

        @include mobile {
            margin-inline: 10px;
            padding: 14px 10px;
        }
    }

    &-Text {
        text-align: end;
        margin-block-end: 0;

        span {
            display: block;
            font-size: 12px;
            font-weight: 400;
        }
    }
    &-PayableAmountTitle {
        color: var(--primary-success-color) !important;
        font-weight: 700;
        text-transform: capitalize;
    }
    &-PayableAmount {
        color: var(--primary-success-color) !important;
        font-weight: 700;
        text-transform: capitalize;
    }

    &-AppendedContent {
        flex-basis: 100%;
        font-size: 12px;
    }

    &-ButtonWrapper {
        @include mobile {
            --footer-totals-height: 123px;
            position: fixed;
            width: 100%;
            inset-inline-start: 0;
            padding-inline: 16px;
            padding-block: 16px;
            padding-block-end: 10px;
            inset-block-end: var(--footer-total-height);
            // background-color: var(--color-white);
            // border-block-start: 1px solid var(--primary-divider-color);
            z-index: 80;
            // border-radius: 30px;
            border-radius: 20px;
            // box-shadow: 0px -5px 10px rgb(238, 238, 238);
            li {
                padding: 14px 0;
            }

            p {
                color: rgb(111, 111, 111);
                letter-spacing: 1px;
                text-transform: capitalize;
                font-size: 15px;
                font-weight: bold;
            }

            strong {
                font-size: 15px;
                color: var(--primary-light-color);
            }
        }

        &_isEmpty {
            display: none;
        }
    }

    &-PayButtonWrapper {
        display: flex;
        justify-content: center;
        margin-top: 3em;
        button {
            width: 100%;
            letter-spacing: 1.5px;
            @include mobile {
                border-radius: 10px !important;
            }
        }
    }

    &-OrderTotals {
        @include mobile {
            box-shadow: 0px 0px 20px rgb(230, 230, 230);
            padding: 10px 15px;
            border-radius: 15px;
            margin-inline: 10px;
            margin-bottom: 20px;
        }
        @include desktop {
            box-shadow: 0px 0px 20px rgb(230, 230, 230);
            padding: 10px 20px;
            border-radius: 15px;
            margin-bottom: 20px;
        }
    }
}
