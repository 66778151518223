/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkout-payment-check-mark-color: var(--primary-base-color);
    --checkout-payment-check-mark-hover-color: var(--secondary-dark-color);
    --checkout-payment-border-color: var(--primary-divider-color);
}

.CheckoutPayment {
    padding-block: 19px;
    padding-inline-start: 0;
    margin-block-end: 0;
    border-block-start: 1px solid var(--checkout-payment-border-color);

    @include tablet {
        width: 100%;
    }

    &::before {
        content: none;
    }

    &:first-child {
        border-block-start: 0;
    }

    &:last-child {
        margin-block-end: 0;
    }

    &-Button {
        --check-mark-opacity: 0;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        letter-spacing: 1px;
        text-align: left;
        line-height: 18px;
    }

    .Field_type_checkbox {
        margin-block-start: 0;
    }
}
