/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CheckoutDeliveryOptions {
    margin-block-end: 24px;
    .ExpandableContent-Button {
        @include mobile {
            background-color: antiquewhite;
            padding: 14px !important;
            border-radius: 10px;
        }
    }
    &-Heading {
        font-weight: 600;
        margin-block: 5px;
        @include mobile {
            font-size: 17px;
            letter-spacing: 1px;
            font-weight: bold;
        }
    }
    @include mobile {
        margin-block-end: 5px;
    }
    @include desktop {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
    }

    @include wide-desktop {
        width: 50%;
    }

    &-NoOptions {
        letter-spacing: 1px;
        color: var(--primary-alt-base-color);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include desktop {
            line-height: 30px;
        }
        @include mobile {
            line-height: 25px;
            padding: 20px 10px;
            box-shadow: 0px 0px 10px #e2e2e2;
            height: 100px;
            margin-top: 10px;
            margin-left: 5px;
            margin-right: 5px;
            border-radius: 10px;
        }
    }
    &-ShopMoreButtonAction {
        background-color: var(--primary-alt-light-color);
        padding: 15px 15px;
        width: 100%;
        text-align: center;
        border-radius: 10px;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 1px;
        color: white;
        text-transform: uppercase;
    }
}
