/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CheckoutBilling {
    @include mobile {
        margin-bottom: 15px;
    }
    @include desktop {
        margin: 15px 0;
        background-color: white;
        border-radius: 5px;
        padding: 20px;
    }
    &-BillingActionButtonStickyBar {
        @include mobile {
            border-block-start: 1px solid var(--primary-divider-color);
            position: fixed;
            inset-inline-start: 0;
            width: 100%;
            z-index: 100;
            bottom: 0px;
            background-color: var(--color-white);
            padding-inline: 16px;
            padding-block-end: 10px;
            border-radius: 30px;
            box-shadow: 0px -5px 10px rgb(238, 238, 238);
        }
        // @include desktop {
        //     display: flex;
        //     justify-content: center;
        // }

        .Button {
            letter-spacing: 2px;
            &:hover {
                background-color: var(--primary-light-color) !important;
            }
            @include mobile {
                width: 100%;
                border-radius: 5px !important;
            }
            @include desktop {
                width: 100%;
                border-radius: 5px;
                &:hover {
                    border-radius: 5px;
                }
            }
        }
    }
    &-Button {
        width: 100%;
        letter-spacing: 1.5px;
        @include desktop {
            max-width: 410px;
        }
        &:hover {
            background-color: var(--primary-light-color);
        }
    }

    &-ChekoutBillingAddressContainer {
        margin-bottom: 20px;
    }
    &-ChekoutBillingAddressWrapper {
        @include mobile {
            box-shadow: 0px 0px 15px #dadada;
            padding: 0 10px;
            padding-left: 20px;
            border-radius: 10px;
            padding-bottom: 14px;
        }
    }

    &-Checkbox {
        display: inline-block;
        font-size: 14px;

        span {
            letter-spacing: 0.7px;
            font-size: 14px;
        }
    }

    &-TermsAndConditions {
        font-size: 14px;
        margin: 0;
        margin-block-end: 24px;
        display: flex;
        align-items: center;

        @include mobile {
            font-size: 15px;
            margin-block-end: 28px;
        }

        &-Checkbox {
            margin-block: 0;

            @include mobile {
                margin-inline-end: 14px;
            }
        }
    }

    &-TACLabel {
        display: inline;
    }

    &-TACLink {
        font-size: 14px;
        font-weight: 700;
        color: var(--link-color);
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &:focus {
            text-decoration: underline;
        }
    }
    .Checkout-Heading {
        margin-top: 0;
        @include desktop {
            margin: 0;
        }
    }
    &-OrderTotal {
        display: flex;
        justify-content: space-between;
        padding-block-end: 14px;

        @include desktop {
            display: none;
        }

        @include mobile {
            padding: 14px 10px;
            dt {
                color: rgb(111, 111, 111);
                letter-spacing: 1px;
                text-transform: capitalize;
                font-size: 15px;
            }
        }
        span {
            display: block;
            font-size: 0.6em;
            font-weight: 300;
        }
    }

    &-OrderTotalWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-OrderHeading {
        letter-spacing: 1px;
        font-size: 16px;
        font-weight: bold;
    }
}
