/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --checkout-max-width: 1100px;
    --checkout-progressbar-step-color: rgb(255, 255, 255);
    --checkout-progressbar-color: rgb(203, 203, 203);
}

.Checkout {
    padding-block-end: var(--header-nav-height);
    @include desktop {
        margin-block-start: var(--header-total-height);
    }

    @include mobile {
        padding-block-end: calc(var(--navigation-tabs-height) + 60px);
        padding-block-start: 20px;
        overflow-x: hidden !important;
    }

    &-progressbar {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    &::before {
        @include desktop {
            content: "";
            background: var(--breadcrumbs-background);
            min-height: var(--breadcrumbs-height);
            display: block;
        }
    }

    // &-StickyButtonWrapper {
    //     @include mobile {
    //         border-block-start: 1px solid var(--primary-divider-color);
    //         position: fixed;
    //         inset-inline-start: 0;
    //         width: 100%;
    //         z-index: 100;
    //         bottom: 0px;
    //         background-color: var(--color-white);
    //         padding-inline: 16px;
    //         padding-block-end: 10px;
    //         border-radius: 30px;
    //         box-shadow: 0px -5px 10px rgb(238, 238, 238);
    //     }
    //     // @include desktop {
    //     //     display: flex;
    //     //     justify-content: center;
    //     // }

    //     .Button {
    //         letter-spacing: 2px;
    //         &:hover {
    //             background-color: var(--primary-light-color) !important;
    //         }
    //         @include mobile {
    //             width: 100%;
    //         }
    //         @include desktop {
    //             width: 100%;
    //             border-radius: 5px;
    //             &:hover {
    //                 border-radius: 5px;
    //             }
    //         }
    //     }
    // }

    &-Wrapper {
        margin: auto;
        padding: 16px;
        margin-bottom: 10px;

        @include desktop {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 10px;
            background-color: rgb(243, 243, 243);
        }
        @include mobile {
            padding-bottom: 7em;
        }

        @include wide-desktop {
            grid-template-columns: 5fr 3fr;
        }
    }

    &-ProgressSection {
        @include mobile {
            display: none;
        }
    }

    &-Heading {
        text-transform: capitalize;
        margin-block: 40px 12px;
        letter-spacing: 1px;
        @include desktop {
            font-size: 18px;
        }
        @include mobile {
            font-size: 15px;
        }
    }

    &-Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-block: 10px;
    }

    &-StepBarTotal {
        background-color: var(--secondary-base-color);
        width: 100%;
        height: 4px;
        border-radius: 2px;
    }

    &-StepBarActive {
        position: relative;
        inset-block-start: -4px;
        background-color: var(--primary-base-color);
        width: 50%;
        height: 4px;
        border-radius: 2px;
        z-index: 2;

        &_isSecond {
            inset-inline-start: 50%;
        }
    }

    &-Title {
        font-size: 36px;
        font-weight: bold;
        text-transform: capitalize;
        @include desktop {
            letter-spacing: 2px;
        }
        @include mobile {
            letter-spacing: 1px;
        }
    }

    &-SelectedStep {
        font-size: 36px;
    }

    &-StepsBorder {
        font-size: 26px;
    }

    &-TotalSteps {
        font-size: 20px;
    }

    &-Coupon {
        @include desktop {
            margin-block-start: 30px;
        }
    }

    &-OrderTotal {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: bold;
        padding-block-end: 14px;

        @include desktop {
            display: none;
        }

        @include mobile {
            padding: 14px 10px;
            dt {
                color: rgb(111, 111, 111);
                letter-spacing: 1px;
                text-transform: capitalize;
                font-size: 15px;
            }
        }
    }
    &-TotalValue {
        font-size: 15px !important;
        color: var(--primary-light-color) !important;
        font-weight: bolder !important;
    }

    &-ShippingButton,
    &-PickInStore {
        padding: 10px 15px;
        background-color: var(--primary-light-color);
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        letter-spacing: 1px;
        width: 100%;
        margin-inline: 5px;
        border-radius: 5px;
        text-transform: uppercase;
        // &:active{
        //     background-color: var(--primary-light-color);
            
        // }
        &_isPickInStore{
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &-DeliverySelect {
        @include mobile {
            margin-block-start: 10px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }

        @include desktop{
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
    }

    .Checkout-ExpandableContentContent {
        margin-block-start: 0;
        padding: 0 16px;
    }

    .CheckoutOrderSummary-ButtonWrapper {
        @include mobile {
            display: none;
        }
    }

    //PROGRESSBAR PART

    @include desktop {
        &-GoBack {
            .BackButton {
                background-color: rgb(229, 239, 229);
                border: none !important;
                border-radius: 50%;
                height: auto;
                padding: 10px;
                position: absolute;
                top: 15px;
                left: 20px;
                z-index: 100;
                transition: all 0.2s linear;
                box-shadow: 0px 0px 10px 0px rgb(94, 94, 94);
                &:hover {
                    box-shadow: 0px 0px 20px 0px rgb(94, 94, 94);
                    transform: scale(1.07);
                    background-color: rgb(208, 226, 208) !important;
                    border: none !important;
                    border-radius: 50% !important;
                    height: auto !important;
                    padding: 10px !important;
                }
                @media screen and (min-width: 811px) and (max-width: 850px) {
                    left: 5px;
                }
            }
        }
    }

    @include desktop {
        &-ProgressBar {
            position: sticky;
            top: var(--header-nav-height);
            z-index: 30;
        }
        &-ProgressBarContainer {
            // clear: both;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--checkout-progressbar-color);
        }
        &-ProgressBarWrapper {
            margin: 0;
            padding: 0;
            list-style-type: none;
            li:last-child {
                margin-bottom: 0;
            }
        }
        &-ProgressBarStep {
            position: relative;
            float: left;
            border-left: 1px solid var(--checkout-progressbar-color);
            background-color: var(--checkout-progressbar-step-color);
            margin-right: 10px;
            margin-block: 15px;
            padding-block: 5px;
            &::before {
                content: "";
            }
            div {
                display: block;
                line-height: 30px;
                padding: 0 20px 0 30px;
                color: grey;
                text-decoration: none;
                cursor: default;
                letter-spacing: 1.5px;
                font-size: 16px;
                transition: all 0.2s linear;
                font-weight: 600;
                em {
                    display: inline-block;
                    border-radius: 50%;
                    background: none;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    text-align: center;
                    margin-right: 14px;
                    border: 1px solid #999;
                    font-size: 13px;
                    font-style: normal;
                    transition: all 0.2s linear;
                }
            }
        }
        &-ProgresBarStepTriangleBefore,
        &-ProgresBarStepTriangle {
            display: block;
            position: absolute;
            top: 0;
            right: -10px;
            height: 0;
            width: 0;
            border-top: 20px solid transparent;
            border-left: 10px solid var(--checkout-progressbar-step-color);
            border-bottom: 20px solid transparent;
            z-index: 1;
            transition: all 0.2s linear;
        }

        &-ProgresBarStepTriangleBefore {
            border-left-color: var(--checkout-progressbar-color);
            left: 0;
        }
    }
    @include mobile {
        &-ProgressBarContainer {
            font-weight: 900;
            font-size: 1.5em;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline: 10px;
            box-shadow: 0px 5px 20px rgb(237, 236, 236);
            padding-bottom: 30px;
        }
        &-ProgressBarWrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 95%;
            max-width: 380px;
            @media screen and (max-width: 350px) {
                width: 300px;
            }
        }
        &-ProgressBarStep {
            z-index: 2;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 20px;
            &:last-child {
                .Checkout-ProgressBarStepNumber {
                    &::after {
                        background: transparent;
                    }
                }
            }
            &:first-child {
                .Checkout-ProgressBarStepNumber {
                    &::before {
                        background: transparent;
                    }
                }
            }
        }
        &-ProgressBarStepNumber {
            color: var(--primary-alt-base-color);
            z-index: 2;
            transition: 0.2s ease;
            display: block;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            text-align: center;
            border: 2px solid var(--primary-alt-base-color);
            font-size: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s linear;
            &::before {
                content: "";
                position: absolute;
                left: -52px;
                top: 14px;
                z-index: -1;
                padding: 0;
                height: 2px;
                width: 50px;
                background: var(--secondary-base-color);
                // @media screen and (max-width: 350px) {
                //     left: -42px;
                //     width: 40px;
                // }
                @media screen and (max-width: 300px) {
                    left: -32px;
                    width: 30px;
                }
            }
            &::after {
                content: "";
                position: absolute;
                left: 33px;
                top: 14px;
                z-index: -1;
                padding: 0;
                height: 2px;
                width: 50px;
                background: var(--secondary-base-color);
                // @media screen and (max-width: 320px) {
                //     width: 43px;
                // }
                // @media screen and (min-width: 321px) and (max-width: 350px) {
                //     width: 50px;
                // }
                @media screen and (max-width: 300px) {
                    left: 33px;
                    width: 30px;
                }
            }
        }

        &-ProgressBarStepLabel {
            position: relative;
            top: 15px;
            font-size: 13px;
            font-weight: 600;
            color: #ccc;
            transition: all 0.2s linear;
            letter-spacing: 1px;
            @media screen and (max-width: 350px) {
                font-size: 14px;
            }
        }
    }

    &-success {
        .Checkout-Wrapper {
            background-color: rgba(250, 240, 240, 0.8) !important;
        }

        @media screen and (max-width: 330px) {
            margin-bottom: 10em;
        }
    }
}
.successPageWrapper {
    @include desktop {
        display: block !important;
    }
    @include wide-desktop {
        display: block !important;
    }
}
.successPageStep {
    display: flex;
    justify-content: center;
    align-items: center;
}
@include desktop {
    .current-step {
        background: var(--primary-alt-light-color);
        transition: all 0.2s linear;

        .Checkout-ProgresBarStepTriangle {
            border-left: 10px solid var(--primary-alt-light-color);
        }

        div {
            color: white;
            em {
                border-color: white;
                padding-left: 2px;
            }
        }
    }
    .visited-step {
        background: var(--primary-light-color);
        transition: all 0.2s linear;

        .Checkout-ProgresBarStepTriangle {
            border-left: 10px solid var(--primary-light-color);
        }
        div {
            color: white;
            em {
                &:before {
                    --rightcheck-color: #fff;
                    content: "";
                    position: absolute;
                    pointer-events: none;
                    background: var(--rightcheck-color);
                    width: 0.24rem;
                    height: 0.24rem;
                    box-shadow: 2px 0 0 var(--rightcheck-color),
                        4px 0 0 var(--rightcheck-color),
                        4px -2px 0 var(--rightcheck-color),
                        4px -4px 0 var(--rightcheck-color),
                        4px -6px 0 var(--rightcheck-color),
                        4px -8px 0 var(--rightcheck-color),
                        4px -10px 0 var(--rightcheck-color);
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    left: 1px;
                    top: 7px;
                    z-index: 20;
                }
                &:after {
                    content: "";
                    position: absolute;
                    pointer-events: none;
                    background: var(--primary-light-color);
                    width: 20px;
                    height: 20px;
                    left: -3px;
                    top: -3px;
                    border-radius: 50%;
                    z-index: 10;
                }
            }
        }
    }
}
@include mobile {
    .current-step {
        .Checkout-ProgressBarStepNumber {
            border-color: var(--primary-alt-light-color);
            &:after {
                background: var(--primary-light-color);
            }
            &:before {
                background: var(--primary-light-color);
            }
        }
        .Checkout-ProgressBarStepLabel,
        .Checkout-ProgressBarStepNumber {
            color: var(--primary-alt-light-color);
        }
    }
    .visited-step {
        .Checkout-ProgressBarStepNumber {
            background-color: var(--primary-light-color);
            color: white;
            border-color: var(--primary-light-color);
            &:before {
                background: var(--primary-light-color);
            }
            &:after {
                background: var(--primary-light-color);
            }
        }
        .Checkout-ProgressBarStep {
            background-color: var(--primary-light-color);
        }
        .Checkout-ProgressBarStepLabel {
            color: var(--primary-light-color);

            &:before {
                --rightcheck-color: #fff;
                content: "";
                position: absolute;
                pointer-events: none;
                background: var(--rightcheck-color);
                width: 3px;
                height: 3px;
                box-shadow: 8px 0 0 var(--rightcheck-color),
                    2px 0 0 var(--rightcheck-color),
                    3px 0 0 var(--rightcheck-color),
                    5px 0 0 var(--rightcheck-color),
                    8px 0 0 var(--rightcheck-color),
                    8px -2px 0 var(--rightcheck-color),
                    8px -4px 0 var(--rightcheck-color),
                    8px -6px 0 var(--rightcheck-color),
                    8px -8px 0 var(--rightcheck-color),
                    8px -10px 0 var(--rightcheck-color),
                    8px -12px 0 var(--rightcheck-color),
                    8px -14px 0 var(--rightcheck-color),
                    8px -16px 0 var(--rightcheck-color);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                left: 20px;
                top: -35px;
                z-index: 20;
                @media screen and (min-width: 320px) and (max-width: 350px) {
                    left: 21px;
                }
                @media screen and (max-width: 320px) {
                    left: 23px;
                }
                @media screen and (max-width: 320px) {
                    left: 23px;
                }
            }
            &:after {
                content: "";
                position: absolute;
                pointer-events: none;
                background: var(--primary-light-color);
                width: 38px;
                height: 38px;
                left: 11px;
                top: -52px;
                border-radius: 50%;
                z-index: 10;
                @media screen and (min-width: 320px) and (max-width: 350px) {
                    left: 12px;
                }
                @media screen and (max-width: 320px) {
                    width: 36px;
                    height: 36px;
                    top: -51px;
                    left: 14px;
                }
            }
        }
    }
}
